import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { WidthLimiterPadding } from '../../components/UI/WidthLimiter';
import { WhyUs } from '../../components/MainPage/WhyUs';
import { Categories } from '../../components/MainPage/Categories';
import { TimeWork } from '../../components/MainPage/TimeWork';
import iconCheck from '../../assets/icons/iconCheck.png';
import picMain from '../../assets/images/picMain.png';
import cls from './MainPage.module.scss';

export const MainPage = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className={cls.topBLockWrapper}>
        <WidthLimiterPadding>
          <div className={cls.topBlock}>
            <div className={cls.left}>
              <h1>{t('MainPage.Top.Title')}</h1>
              <p>{t('MainPage.Top.Text')}</p>
              <ul className={cls.list}>
                <li>
                  <img src={iconCheck} alt="iconCheck" />
                  <span>{t('MainPage.Top.li1')}</span>
                </li>
                <li>
                  <img src={iconCheck} alt="iconCheck" />
                  <span>{t('MainPage.Top.li2')}</span>
                </li>
                <li>
                  <img src={iconCheck} alt="iconCheck" />
                  <span>{t('MainPage.Top.li3')}</span>
                </li>
              </ul>
            </div>
            <div className={cls.right}>
              <img src={picMain} alt="picMain" />
            </div>
          </div>
        </WidthLimiterPadding>
      </div>
      <TimeWork />
      <WhyUs />
      <Categories />
    </>
  );
};
