import React from 'react';
import { WidthLimiterPadding } from '../UI/WidthLimiter';
import { Title } from '../UI/Title/Title';
import { CardListItem } from '../UI/CardListItem/CardListItem';
import { CustomsListItem } from './CustomsListItem';
import { useTranslation } from 'react-i18next';
import cls from './Procedure.module.scss';

export const Procedure = () => {
  const { t } = useTranslation();

  return (
    <WidthLimiterPadding>
      <div className={cls.container}>
        <Title>{t('CustomsPage.process.title')}</Title>
        <CustomsListItem
          title={t('CustomsPage.process.Preparation.title')}
          count={1}
        >
          <CardListItem
            count={'1.1'}
            text={t('CustomsPage.process.Preparation.li1')}
          />
          <CardListItem
            count={'1.2'}
            text={t('CustomsPage.process.Preparation.li2')}
          />
          <CardListItem
            count={'1.3'}
            text={t('CustomsPage.process.Preparation.li3')}
            span={true}
          />
        </CustomsListItem>

        <CustomsListItem
          title={t('CustomsPage.process.Subject analysis.title')}
          count={2}
        >
          <CardListItem
            count={'2.1'}
            text={t('CustomsPage.process.Subject analysis.li1')}
          />
          <CardListItem
            count={'2.2'}
            text={t('CustomsPage.process.Subject analysis.li2')}
          />
          <CardListItem
            count={'2.3'}
            text={t('CustomsPage.process.Subject analysis.li3')}
            span={true}
          />
        </CustomsListItem>

        <CustomsListItem
          title={t('CustomsPage.process.clearance procedure.title')}
          count={3}
        >
          <CardListItem
            count={'3.1'}
            text={t('CustomsPage.process.clearance procedure.li1')}
            span={true}
          />
          <CardListItem
            count={'3.2'}
            text={t('CustomsPage.process.clearance procedure.li2')}
          />
          <CardListItem
            count={'3.3'}
            text={t('CustomsPage.process.clearance procedure.li3')}
          />
          <CardListItem
            count={'3.4'}
            text={t('CustomsPage.process.clearance procedure.li4')}
          />
          <CardListItem
            count={'3.5'}
            text={t('CustomsPage.process.clearance procedure.li5')}
          />
        </CustomsListItem>

        <CustomsListItem
          title={t('CustomsPage.process.Final procedure.title')}
          count={4}
        >
          <CardListItem
            count={'4.1'}
            text={t('CustomsPage.process.Final procedure.li1')}
            span={true}
          />
        </CustomsListItem>
      </div>
    </WidthLimiterPadding>
  );
};
