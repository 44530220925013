import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TitleBlock } from '../../components/TitleBlock/TitleBlock';
import { WidthLimiterPadding } from '../../components/UI/WidthLimiter';
import { Title } from '../../components/UI/Title/Title';
import { Text } from '../../components/UI/Text/Text';
import { CheckListItem } from '../../components/UI/CheckListItem/CheckListItem';
import { CardListItem } from '../../components/UI/CardListItem/CardListItem';
import cls from './PaymentPage.module.scss';
import img from '../../assets/images/picPayAgent.png';

export const PaymentPage = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={cls.container}>
      <TitleBlock
        title={t('PaymentPage.top.Title')}
        text1={t('PaymentPage.top.text1')}
        text2={t('PaymentPage.top.text2')}
        image={img}
      />
      <WidthLimiterPadding>
        <div className={cls.content}>
          <div className={cls.paymentAgent}>
            <Title>{t('PaymentPage.agent.Title')}</Title>
            <Text>{t('PaymentPage.agent.text1')}</Text>
            <div className={cls.agentInfo}>
              <Text>{t('PaymentPage.agent.infoText')}</Text>
              <ul className={cls.infoList}>
                <CheckListItem text={t('PaymentPage.agent.li1')} />
                <CheckListItem text={t('PaymentPage.agent.li2')} />
              </ul>
            </div>
            <Text>{t('PaymentPage.agent.text2')}</Text>
          </div>
          <div className={cls.support}>
            <Title>{t('PaymentPage.support.title')}</Title>
            <ul className={cls.supportList}>
              <CardListItem count={'1'} text={t('PaymentPage.support.li1')} />
              <CardListItem count={'2'} text={t('PaymentPage.support.li2')} />
              <CardListItem count={'3'} text={t('PaymentPage.support.li3')} />
            </ul>
          </div>
        </div>
      </WidthLimiterPadding>
    </div>
  );
};
