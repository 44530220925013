import * as React from 'react';
import cls from './TimeWork.module.scss';
import { WidthLimiterPadding } from '../UI/WidthLimiter';
import { Title } from '../UI/Title/Title';
import { Text } from '../UI/Text/Text';
import { useTranslation } from 'react-i18next';

export const TimeWork = () => {
  const { t } = useTranslation();

  return (
    <div className={cls.container}>
      <WidthLimiterPadding>
        <div className={cls.timeWork}>
          <Title>{t('MainPage.Work.Title')}</Title>
          <Text>{t('MainPage.Work.text')}</Text>
        </div>
      </WidthLimiterPadding>
    </div>
  );
};
