import img1 from '../../assets/langFlags/poland.png';
import img2 from '../../assets/langFlags/germany.png';
import img3 from '../../assets/langFlags/czech republic.png';
import img4 from '../../assets/langFlags/slovakia.png';
import img5 from '../../assets/langFlags/serbia.png';
import img6 from '../../assets/langFlags/united states.png';
import img7 from '../../assets/langFlags/china.png';
import img8 from '../../assets/langFlags/turkey.png';
import img9 from '../../assets/langFlags/thailand.png';
import img10 from '../../assets/langFlags/israel.png';
import img11 from '../../assets/langFlags/india.png';
import img12 from '../../assets/langFlags/vietnam.png';
import img13 from '../../assets/langFlags/australia.png';
import img14 from '../../assets/langFlags/brazil.png';
import img15 from '../../assets/langFlags/hong kong.png';
import img16 from '../../assets/langFlags/canada.png';
import img17 from '../../assets/langFlags/south korea.png';
import img18 from '../../assets/langFlags/japan.png';
import img19 from '../../assets/langFlags/malaysia.png';
import img20 from '../../assets/langFlags/indonesia.png';
import img21 from '../../assets/langFlags/taiwan.png';
import img22 from '../../assets/langFlags/united arab emirates.png';

export const countries = [
  {
    label: 'Countries.Poland',
    image: img1
  },
  {
    label: 'Countries.germany',
    image: img2
  },
  {
    label: 'Countries.czech',
    image: img3
  },
  {
    label: 'Countries.slovakia',
    image: img4
  },
  {
    label: 'Countries.serbia',
    image: img5
  },
  {
    label: 'Countries.unitedStates',
    image: img6
  },
  {
    label: 'Countries.china',
    image: img7
  },
  {
    label: 'Countries.turkey',
    image: img8
  },
  {
    label: 'Countries.thailand',
    image: img9
  },
  {
    label: 'Countries.israel',
    image: img10
  },
  {
    label: 'Countries.india',
    image: img11
  },
  {
    label: 'Countries.vietnam',
    image: img12
  },
  {
    label: 'Countries.australia',
    image: img13
  },
  {
    label: 'Countries.brazil',
    image: img14
  },
  {
    label: 'Countries.hong kong',
    image: img15
  },
  {
    label: 'Countries.canada',
    image: img16
  },
  {
    label: 'Countries.south korea',
    image: img17
  },
  {
    label: 'Countries.japan',
    image: img18
  },
  {
    label: 'Countries.malaysia',
    image: img19
  },
  {
    label: 'Countries.indonesia',
    image: img20
  },
  {
    label: 'Countries.taiwan',
    image: img21
  },
  {
    label: 'Countries.united arab emirates',
    image: img22
  }
];
