import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TitleBlock } from '../../components/TitleBlock/TitleBlock';
import { Geography } from '../../components/ShippingPage/Geography';
import { Guarantees } from '../../components/ShippingPage/Guarantees';
import { Cargo } from '../../components/ShippingPage/Cargo';
import { Price, Transport } from '../../components/ShippingPage/Transport';
import { Delivery } from '../../components/ShippingPage/Delivery';
import img from '../../assets/images/cargo/picCargo5.png';
import cls from './ShippingPage.module.scss';

export const ShippingPage = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={cls.container}>
      <TitleBlock
        title={t('ShippingPage.top.Title')}
        text1={t('ShippingPage.top.text1')}
        text2={t('ShippingPage.top.text2')}
        image={img}
      />
      <Geography />
      <Guarantees />
      <Cargo />
      <Transport />
      <Delivery />
      <Price />
    </div>
  );
};
