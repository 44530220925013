import React from 'react';
import cls from './Button.module.scss';
import { Link } from 'react-router-dom';

interface Props {
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  children?: React.ReactNode;
}

export const Button = ({ children, disabled, ...props }: Props) => {
  return (
    <button className={cls.btn} disabled={disabled} {...props}>
      {disabled ? 'Отправка...' : children}
    </button>
  );
};

interface LinkProps {
  to: string;
  children: React.ReactNode;
}

export const LinkButton = ({ children, to, ...props }: LinkProps) => {
  return (
    <Link to={to} className={cls.btn} {...props}>
      {children}
    </Link>
  );
};
