import * as React from 'react';
import cls from './Categories.module.scss';
import { WidthLimiterPadding } from '../UI/WidthLimiter';
import { Title } from '../UI/Title/Title';
import { categories } from '../../app/configs/allCategories';
import { useTranslation } from 'react-i18next';

export const Categories = () => {
  const { t } = useTranslation();

  return (
    <div className={cls.container}>
      <WidthLimiterPadding>
        <div className={cls.categories}>
          <Title>{t('MainPage.Categories.Title')}</Title>
          <ul className={cls.list}>
            {categories.map((item) => (
              <li key={item.text} className={cls.item}>
                <img src={item.image} alt="pic categories" />
                <span>{t(item.text)}</span>
              </li>
            ))}
          </ul>
        </div>
      </WidthLimiterPadding>
    </div>
  );
};
