import React from 'react';
import { WidthLimiterPadding } from '../UI/WidthLimiter';
import { Title } from '../UI/Title/Title';
import { Text } from '../UI/Text/Text';
import { CheckListItem } from '../UI/CheckListItem/CheckListItem';
import { useTranslation } from 'react-i18next';
import image from '../../assets/images/picGuarantees.png';
import cls from './Guarantees.module.scss';

export const Guarantees = () => {
  const { t } = useTranslation();

  return (
    <div className={cls.guarantees}>
      <WidthLimiterPadding>
        <div className={cls.container}>
          <img className={cls.image1} src={image} alt="guarantees" />
          <div className={cls.block}>
            <div className={cls.top}>
              <img className={cls.image2} src={image} alt="guarantees" />
              <div className={cls.title}>
                <Title>{t('ShippingPage.guarantees.title')}</Title>
                <Text>{t('ShippingPage.guarantees.text')}</Text>
              </div>
            </div>

            <ul className={cls.list}>
              <CheckListItem text={t('ShippingPage.guarantees.li1')} />
              <CheckListItem text={t('ShippingPage.guarantees.li2')} />
              <CheckListItem text={t('ShippingPage.guarantees.li3')} />
              <CheckListItem text={t('ShippingPage.guarantees.li4')} />
            </ul>
          </div>
        </div>
      </WidthLimiterPadding>
    </div>
  );
};
