import { useTranslation } from 'react-i18next';
import { TitleBlock } from '../../components/TitleBlock/TitleBlock';
import { Procedure } from '../../components/CustomsPage/Procedure';
import { Documents } from '../../components/CustomsPage/Documents';
import img from '../../assets/images/picCustoms.png';
import cls from './CustomsPage.module.scss';
import { useEffect } from 'react';

export const CustomsPage = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={cls.container}>
      <TitleBlock
        title={t('CustomsPage.top.Title')}
        text1={t('CustomsPage.top.text1')}
        text2={t('CustomsPage.top.text2')}
        image={img}
      />
      <Procedure />
      <Documents />
    </div>
  );
};
