import React from 'react';
import cls from './Delivery.module.scss';
import { WidthLimiterPadding } from '../UI/WidthLimiter';
import { Title } from '../UI/Title/Title';
import { CardListItem } from '../UI/CardListItem/CardListItem';
import { useTranslation } from 'react-i18next';

export const Delivery = () => {
  const { t } = useTranslation();

  return (
    <WidthLimiterPadding>
      <div className={cls.container}>
        <Title>{t('ShippingPage.Delivery.Title')}</Title>
        <ul className={cls.list}>
          <li>
            <CardListItem count={'1'} text={t('ShippingPage.Delivery.li1')} />
          </li>
          <li>
            <CardListItem count={'2'} text={t('ShippingPage.Delivery.li2')} />
          </li>
          <li>
            <CardListItem count={'3'} text={t('ShippingPage.Delivery.li3')} />
          </li>
          <li>
            <CardListItem count={'4'} text={t('ShippingPage.Delivery.li4')} />
          </li>
          <li>
            <CardListItem count={'5'} text={t('ShippingPage.Delivery.li5')} />
          </li>
          <li>
            <CardListItem count={'6'} text={t('ShippingPage.Delivery.li6')} />
          </li>
        </ul>
      </div>
    </WidthLimiterPadding>
  );
};
