import React, { ReactNode } from 'react';
import cls from './Text.module.scss';

export const Text = ({
  children,
  white
}: {
  children: ReactNode;
  white?: boolean;
}) => {
  return (
    <p
      className={cls.content}
      style={white ? { color: 'rgba(255, 255, 255, 0.70)' } : {}}
    >
      {children}
    </p>
  );
};
