import React from 'react';
import cls from './CheckListItem.module.scss';
import iconCheck from '../../../assets/icons/iconCheck2.png';

type ListItemProps = {
  text: string;
};

export const CheckListItem = ({ text }: ListItemProps) => {
  return (
    <li className={cls.listItem}>
      <img src={iconCheck} alt="check" />
      <div>{text}</div>
    </li>
  );
};
