import React from 'react';
import { WidthLimiter } from '../UI/WidthLimiter';
import { Title } from '../UI/Title/Title';
import { useKeenSlider } from 'keen-slider/react';
import { countries } from '../../app/configs/countries';
import { Text } from '../UI/Text/Text';
import { useTranslation } from 'react-i18next';
import cls from './Geography.module.scss';
import 'keen-slider/keen-slider.min.css';

const animation = { duration: 12000, easing: (t: number) => t };

export const Geography = () => {
  const { t } = useTranslation();

  const [sliderRef] = useKeenSlider<HTMLDivElement>({
    loop: true,
    renderMode: 'performance',
    drag: false,
    breakpoints: {
      '(min-width: 300px)': {
        slides: { perView: 3, spacing: 5 }
      },
      '(min-width: 600px)': {
        slides: { perView: 4, spacing: 10 }
      },
      '(min-width: 1000px)': {
        slides: { perView: 6, spacing: 15 }
      },
      '(min-width: 1400px)': {
        slides: { perView: 8, spacing: 15 }
      },
      '(min-width: 1920px)': {
        slides: { perView: 12, spacing: 15 }
      }
    },
    slides: { perView: 2, spacing: 5 },
    created(s) {
      s.moveToIdx(5, true, animation);
    },
    updated(s) {
      s.moveToIdx(s.track.details.abs + 5, true, animation);
    },
    animationEnded(s) {
      s.moveToIdx(s.track.details.abs + 5, true, animation);
    }
  });

  return (
    <div className={cls.container}>
      <WidthLimiter>
        <Title>{t('ShippingPage.geography.title')}</Title>
      </WidthLimiter>
      <div ref={sliderRef} className="keen-slider">
        {countries.map((item, i) => (
          <div className="keen-slider__slide" key={i}>
            <div className={cls.item}>
              <img src={item.image} alt="country-icon" />
              <Text>{t(item.label)}</Text>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
