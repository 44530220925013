import pic1 from '../../assets/images/categories/pic1.png';
import pic2 from '../../assets/images/categories/pic2.png';
import pic3 from '../../assets/images/categories/pic3.png';
import pic4 from '../../assets/images/categories/pic4.png';
import pic5 from '../../assets/images/categories/pic5.png';
import pic6 from '../../assets/images/categories/pic6.png';
import pic7 from '../../assets/images/categories/pic7.png';
import pic8 from '../../assets/images/categories/pic8.png';
import pic9 from '../../assets/images/categories/pic9.png';
import pic10 from '../../assets/images/categories/pic10.png';
import pic11 from '../../assets/images/categories/pic11.png';
import pic12 from '../../assets/images/categories/pic1.png';
import pic13 from '../../assets/images/categories/pic13.png';
import pic14 from '../../assets/images/categories/pic14.png';
import pic15 from '../../assets/images/categories/pic15.png';
import pic16 from '../../assets/images/categories/pic16.png';
import pic17 from '../../assets/images/categories/pic17.png';
import pic18 from '../../assets/images/categories/pic18.png';

export const categories = [
  {
    text: 'MainPage.Categories.li1',
    image: pic1
  },
  {
    text: 'MainPage.Categories.li2',
    image: pic2
  },
  {
    text: 'MainPage.Categories.li3',
    image: pic3
  },
  {
    text: 'MainPage.Categories.li4',
    image: pic4
  },
  {
    text: 'MainPage.Categories.li5',
    image: pic5
  },
  {
    text: 'MainPage.Categories.li6',
    image: pic6
  },
  {
    text: 'MainPage.Categories.li7',
    image: pic7
  },
  {
    text: 'MainPage.Categories.li8',
    image: pic8
  },
  {
    text: 'MainPage.Categories.li9',
    image: pic9
  },
  {
    text: 'MainPage.Categories.li10',
    image: pic10
  },
  {
    text: 'MainPage.Categories.li11',
    image: pic11
  },
  {
    text: 'MainPage.Categories.li12',
    image: pic12
  },
  {
    text: 'MainPage.Categories.li13',
    image: pic13
  },
  {
    text: 'MainPage.Categories.li14',
    image: pic14
  },
  {
    text: 'MainPage.Categories.li15',
    image: pic15
  },
  {
    text: 'MainPage.Categories.li16',
    image: pic16
  },
  {
    text: 'MainPage.Categories.li17',
    image: pic17
  },
  {
    text: 'MainPage.Categories.li18',
    image: pic18
  }
];
