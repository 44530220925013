import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOnClickOutside } from 'usehooks-ts';
import flagEn from '../../assets/langFlags/flagsEn.png';
import flagRu from '../../assets/langFlags/flagsRu.png';
import flagKg from '../../assets/langFlags/flagsKg.png';
import iconSelect from '../../assets/langFlags/iconSelect.png';
import cls from './LangSelector.module.scss';

interface Option {
  value: string;
  label: string;
  icon: string;
}

const optionsMap = new Map<string, Option>([
  ['ru', { value: 'ru', label: 'Русский', icon: flagRu }],
  ['en', { value: 'en', label: 'English', icon: flagEn }],
  ['kg', { value: 'kg', label: 'Кыргыз', icon: flagKg }]
]);

const options: Option[] = [
  { value: 'ru', label: 'Русский', icon: flagRu },
  { value: 'en', label: 'English', icon: flagEn },
  { value: 'kg', label: 'Кыргыз', icon: flagKg }
];

export const LangSelector = () => {
  const ref = useRef(null);
  const { i18n } = useTranslation();
  const [selectedOption, setSelectedOption] = useState(
    optionsMap.get(i18n.language)
  );
  const [showOptions, setShowOptions] = useState(false);

  const handleSelect = (option: Option) => {
    setSelectedOption(option);
    i18n.changeLanguage(option.value);
    setShowOptions(false);
  };

  const handleShowOptions = () => {
    setShowOptions((prevState) => !prevState);
  };

  const handleClickOutside = () => {
    setShowOptions(false);
  };

  useOnClickOutside(ref, handleClickOutside);

  return (
    <div className={cls.container} ref={ref}>
      <button className={cls.select} onClick={handleShowOptions}>
        <img className={cls.flag} src={selectedOption?.icon} alt="flagIcon" />
        <img
          className={`${cls.iconSelect} ${showOptions ? cls.iconActive : ''}`}
          src={iconSelect}
          alt="select"
        />
      </button>
      <div className={`${cls.options} ${showOptions ? cls.show : ''}`}>
        {options.map((option) => (
          <div
            key={option.value}
            className={cls.option}
            onClick={() => handleSelect(option)}
          >
            <div>{option.label}</div>
            <img src={option.icon} alt="flagIcon" />
          </div>
        ))}
      </div>
    </div>
  );
};
