import cls from './CustomsListItem.module.scss';

type Props = {
  title: string;
  count: number;
  children: React.ReactNode;
};
export const CustomsListItem = ({ title, count, children }: Props) => {
  return (
    <div className={cls.stage}>
      <div className={cls.leftTitle}>
        <h5>{title}</h5>
        <div className={cls.leftNumber}>{count}</div>
      </div>
      <ul className={cls.list}>{children}</ul>
    </div>
  );
};
