import React from 'react';
import { useTranslation } from 'react-i18next';
import { WidthLimiterPadding } from '../UI/WidthLimiter';
import { Title } from '../UI/Title/Title';
import { Text } from '../UI/Text/Text';
import iconCheck from '../../assets/icons/iconCheck.png';
import cls from './Transport.module.scss';

export const Transport = () => {
  const { t } = useTranslation();

  return (
    <div className={cls.transport}>
      <WidthLimiterPadding>
        <div className={cls.container}>
          <div className={cls.block}>
            <Title>{t('ShippingPage.transport.Title')}</Title>
            <Text>{t('ShippingPage.transport.text')}</Text>
            <ul className={cls.list}>
              <li>
                <img src={iconCheck} alt="iconCheck" />
                <span>{t('ShippingPage.transport.li1')}</span>
              </li>
              <li>
                <img src={iconCheck} alt="iconCheck" />
                <span>{t('ShippingPage.transport.li2')}</span>
              </li>
              <li>
                <img src={iconCheck} alt="iconCheck" />
                <span>{t('ShippingPage.transport.li3')}</span>
              </li>
            </ul>
          </div>
        </div>
      </WidthLimiterPadding>
    </div>
  );
};

export const Price = () => {
  const { t } = useTranslation();

  return (
    <div className={cls.transport}>
      <WidthLimiterPadding>
        <div className={cls.container}>
          <div className={cls.block}>
            <Title>{t('ShippingPage.Price.Title')}</Title>
            <Text>{t('ShippingPage.Price.text1')}</Text>
            <Text>{t('ShippingPage.Price.text2')}</Text>
          </div>
        </div>
      </WidthLimiterPadding>
    </div>
  );
};
