import { Paths } from '../providers/router/Paths';

export const navLinks = [
  {
    to: Paths.CUSTOMS,
    label: 'Nav.Customs clearance'
  },
  {
    to: Paths.SHIPPING,
    label: 'Nav.International transportation'
  },
  {
    to: Paths.PAYMENT_AGENT,
    label: 'Nav.Payment agent'
  }
];
