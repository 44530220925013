import React from 'react';
import cls from './Documents.module.scss';
import { WidthLimiterPadding } from '../UI/WidthLimiter';
import { Title } from '../UI/Title/Title';
import image from '../../assets/images/picDocuments.png';
import iconCheck from '../../assets/icons/iconCheck2.png';
import { useTranslation } from 'react-i18next';

type ListItemProps = {
  text: string;
};

const DocListItem = ({ text }: ListItemProps) => {
  return (
    <li className={cls.listItem}>
      <div>{text}</div>
      <img src={iconCheck} alt="check" />
    </li>
  );
};

export const Documents = () => {
  const { t } = useTranslation();

  return (
    <div className={cls.documents}>
      <WidthLimiterPadding>
        <div className={cls.container}>
          <div className={cls.title}>
            <img src={image} alt="docs" />
            <Title>{t('CustomsPage.docs.title')}</Title>
          </div>

          <div className={cls.block}>
            <ul className={cls.list}>
              <DocListItem text={t('CustomsPage.docs.li1')} />
              <DocListItem text={t('CustomsPage.docs.li2')} />
              <DocListItem text={t('CustomsPage.docs.li3')} />
              <DocListItem text={t('CustomsPage.docs.li4')} />
              <DocListItem text={t('CustomsPage.docs.li5')} />
              <DocListItem text={t('CustomsPage.docs.li6')} />
              <DocListItem text={t('CustomsPage.docs.li7')} />
            </ul>
            <img className={cls.docsImg} src={image} alt="docs" />
            <ul className={cls.list}>
              <DocListItem text={t('CustomsPage.docs.li8')} />
              <DocListItem text={t('CustomsPage.docs.li9')} />
              <DocListItem text={t('CustomsPage.docs.li10')} />
              <DocListItem text={t('CustomsPage.docs.li11')} />
              <DocListItem text={t('CustomsPage.docs.li12')} />
            </ul>
          </div>
        </div>
      </WidthLimiterPadding>
    </div>
  );
};
