import { WidthLimiterPadding } from '../UI/WidthLimiter';
import { Title } from '../UI/Title/Title';
import { Text } from '../UI/Text/Text';
import imgCargo1 from '../../assets/images/cargo/picCargo1.png';
import imgCargo2 from '../../assets/images/cargo/picCargo2.png';
import imgCargo3 from '../../assets/images/cargo/picCargo3.png';
import imgCargo4 from '../../assets/images/cargo/picCargo4.png';
import imgCargo5 from '../../assets/images/cargo/picCargo5.png';
import icon1 from '../../assets/icons/caTime.svg';
import icon2 from '../../assets/icons/caMoney.svg';
import icon3 from '../../assets/icons/caGraph.svg';
import icon4 from '../../assets/icons/caStar.svg';
import cls from './Cargo.module.scss';
import { useTranslation } from 'react-i18next';

export const Cargo = () => {
  const { t } = useTranslation();

  return (
    <WidthLimiterPadding>
      <div className={cls.container}>
        <div className={cls.title}>
          <Title>{t('ShippingPage.cargo.Title')}</Title>
          <Text>{t('ShippingPage.cargo.text')}</Text>
        </div>
        <ul className={cls.list}>
          <li>
            <div className={cls.card}>
              <div className={cls.text}>
                <h5>{t('ShippingPage.cargo.list.title1')}</h5>
                <p>{t('ShippingPage.cargo.list.text1')}</p>
              </div>
              <div className={cls.grey}>
                <img src={icon1} alt="icon1" />
                <span>{t('ShippingPage.cargo.list.option1')}</span>
              </div>
            </div>
          </li>
          <li className={cls.cardImage}>
            <img src={imgCargo1} alt="cargo image" />
          </li>

          <li className={cls.cardImage}>
            <img src={imgCargo2} alt="cargo image" />
          </li>
          <li>
            <div className={cls.card}>
              <div className={cls.text}>
                <h5>{t('ShippingPage.cargo.list.title2')}</h5>
                <p>{t('ShippingPage.cargo.list.text2')}</p>
              </div>
              <div className={cls.grey}>
                <img src={icon2} alt="icon1" />
                <span>{t('ShippingPage.cargo.list.option2')}</span>
              </div>
            </div>
          </li>

          <li>
            <div className={cls.card}>
              <div className={cls.text}>
                <h5>{t('ShippingPage.cargo.list.title3')}</h5>
                <p>{t('ShippingPage.cargo.list.text3')}</p>
              </div>
              <div className={cls.grey}>
                <img src={icon3} alt="icon1" />
                <span>{t('ShippingPage.cargo.list.option3')}</span>
              </div>
            </div>
          </li>
          <li className={cls.cardImage}>
            <img src={imgCargo3} alt="cargo image" />
          </li>

          <li className={cls.cardImage}>
            <img src={imgCargo4} alt="cargo image" />
          </li>
          <li>
            <div className={cls.card}>
              <div className={cls.text}>
                <h5>{t('ShippingPage.cargo.list.title4')}</h5>
                <p>{t('ShippingPage.cargo.list.text4')}</p>
              </div>
              <div className={cls.grey}>
                <img src={icon4} alt="icon1" />
                <span>{t('ShippingPage.cargo.list.option4')}</span>
              </div>
            </div>
          </li>

          <li>
            <div className={cls.card}>
              <div className={cls.text}>
                <h5>{t('ShippingPage.cargo.list.title5')}</h5>
                <p>{t('ShippingPage.cargo.list.text5')}</p>
              </div>
              <div className={cls.grey}>
                <img src={icon4} alt="icon1" />
                <span>{t('ShippingPage.cargo.list.option5')}</span>
              </div>
            </div>
          </li>
          <li>
            <div className={cls.cardImage}>
              <img src={imgCargo5} alt="cargo image" />
            </div>
          </li>
        </ul>
      </div>
    </WidthLimiterPadding>
  );
};
