import { useState } from 'react';
import { WidthLimiter } from '../UI/WidthLimiter';
import { NavLink } from 'react-router-dom';
import { Paths } from '../../app/providers/router/Paths';
import Drawer from 'react-modern-drawer';
import { navLinks } from '../../app/configs/navLinks';
import { CustomNavLink } from '../CustomNavLink/CustomNavLink';
import { ReactComponent as LogoFgb } from '../../assets/LogoFgb.svg';
import { ReactComponent as IconBurger } from '../../assets/burger-icon.svg';
import { ReactComponent as IconBurgerClose } from '../../assets/burger-close.svg';
import { LangSelector } from '../LangSelector/LangSelector';
import 'react-modern-drawer/dist/index.css';
import cls from './Header.module.scss';
import { useTranslation } from 'react-i18next';

export const Header = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  const closeDrawer = () => {
    setIsOpen(false);
  };

  return (
    <header className={cls.header}>
      <WidthLimiter>
        <Drawer
          open={isOpen}
          onClose={toggleDrawer}
          direction="top"
          size="100vh"
          className={cls.drawer}
        >
          <div className={cls.drawerContent}>
            <nav className={cls.navDrawer}>
              <div className={cls.mobLine}>
                <span>{t('Other.changeLang')}</span>
                <LangSelector />
              </div>
              {navLinks.map((item) => (
                <CustomNavLink
                  key={item.to}
                  to={item.to}
                  onClick={toggleDrawer}
                >
                  {t(item.label)}
                </CustomNavLink>
              ))}
            </nav>
          </div>
        </Drawer>
        <div className={cls.container}>
          <NavLink
            to={Paths.APP}
            style={{ display: 'flex' }}
            onClick={closeDrawer}
          >
            <LogoFgb className={cls.logo} />
          </NavLink>
          <div className={cls.toolbar}>
            <div className={cls.burgerContainer}>
              <button onClick={toggleDrawer}>
                {isOpen ? <IconBurgerClose /> : <IconBurger />}
              </button>
            </div>
            <nav className={cls.nav}>
              {navLinks.map((item) => (
                <CustomNavLink key={item.to} to={item.to}>
                  {t(item.label)}
                </CustomNavLink>
              ))}
            </nav>
            <div className={cls.lang}>
              <LangSelector />
            </div>
          </div>
        </div>
      </WidthLimiter>
    </header>
  );
};
