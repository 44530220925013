import React from 'react';
import cls from './ImportPage.module.scss';
import { WidthLimiter } from '../../components/UI/WidthLimiter';
import { Title } from '../../components/UI/Title/Title';
import { useTranslation } from 'react-i18next';

export const ImportPage = () => {
  const { t } = useTranslation();

  return (
    <WidthLimiter>
      <div className={cls.container}>
        <div className={cls.categoriesList}>
          <Title>{t('importPage.categoriesTitle')}</Title>
        </div>
      </div>
    </WidthLimiter>
  );
};
