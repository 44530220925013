import { WidthLimiterPadding } from '../UI/WidthLimiter';
import { Paths } from '../../app/providers/router/Paths';
import { CustomLink } from '../CustomNavLink/CustomNavLink';
import { navLinks } from '../../app/configs/navLinks';
import { useTranslation } from 'react-i18next';
import { OrderForm } from '../OrderForm/OrderForm';
import cls from './Footer.module.scss';

export const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer>
      <WidthLimiterPadding>
        <div className={cls.container}>
          <div className={cls.form}>
            <OrderForm />
          </div>
          <div className={cls.divider}></div>
          <div className={cls.footer}>
            <div className={cls.footerTitle}>
              <h4>{t('Footer.title')}</h4>
              <span>{t('Footer.text')}</span>
            </div>
            <div className={cls.footerInfo}>
              <div className={cls.col}>
                <h5>{t('Footer.contacts.title')}</h5>
                <ul>
                  <li>
                    <label>{t('Footer.contacts.sales')}</label>
                    {t('Footer.contacts.phone')}
                  </li>
                  <li>
                    <label>{t('Footer.contacts.emailTitle')}</label>
                    {t('Footer.contacts.email')}
                  </li>
                  <li>
                    <CustomLink to={Paths.CONTACTS}>
                      {t('Footer.contacts.RequisitesLink')}
                    </CustomLink>
                  </li>
                </ul>
              </div>
              <div className={cls.col}>
                <h5>{t('Footer.links.title')}</h5>
                <ul>
                  {navLinks.map((item) => (
                    <CustomLink key={item.to} to={item.to}>
                      {t(item.label)}
                    </CustomLink>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </WidthLimiterPadding>
    </footer>
  );
};
