import { useTranslation } from 'react-i18next';
import cls from './ContactsPage.module.scss';
import { WidthLimiter } from '../../components/UI/WidthLimiter';
import { Title } from '../../components/UI/Title/Title';
import React, { useEffect } from 'react';
import { Text } from '../../components/UI/Text/Text';
import icon1 from '../../assets/icons/iconAddress.png';
import icon2 from '../../assets/icons/iconMail.png';
import icon3 from '../../assets/icons/iconPhone.png';

export const ContactsPage = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={cls.container}>
      <WidthLimiter>
        <div className={cls.contacts}>
          <Title>{t('DetailsPage.Title')}</Title>
          <div className={cls.text}>{t('DetailsPage.text')}</div>
          <div className={cls.block}>
            <ul className={cls.list}>
              <li className={cls.listItem}>
                <img src={icon1} alt="address" />
                <Text>{t('DetailsPage.list.address')}</Text>
              </li>
              <li className={cls.listItem}>
                <img src={icon2} alt="mail" />
                <Text>{t('DetailsPage.list.mail')}</Text>
              </li>
              <li className={cls.listItem}>
                <img src={icon3} alt="phone" />
                <Text>{t('DetailsPage.list.phone')}</Text>
              </li>
            </ul>
            <ul className={cls.info}>
              <li>
                <Text>{t('DetailsPage.info.li1')}</Text>
              </li>
              <li>
                <Text>{t('DetailsPage.info.li2')}</Text>
              </li>
              <li>
                <Text>{t('DetailsPage.info.li3')}</Text>
              </li>
              <li>
                <Text>{t('DetailsPage.info.li4')}</Text>
              </li>
              <li>
                <Text>{t('DetailsPage.info.li5')}</Text>
              </li>
              <br />
              <li>
                <Text>{t('DetailsPage.info.li6')}</Text>
              </li>
              <li>
                <Text>{t('DetailsPage.info.li7')}</Text>
              </li>
            </ul>
          </div>
        </div>
      </WidthLimiter>
    </div>
  );
};
