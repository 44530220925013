import cls from './CardListItem.module.scss';

type Props = {
  count: string;
  text: string;
  span?: boolean;
};

export const CardListItem = ({ count, text, span }: Props) => {
  const style = `${cls.item} ${span ? `${cls.colSpan}` : ''}`;

  return (
    <li className={style}>
      <div>{count}</div>
      <p>{text}</p>
    </li>
  );
};
