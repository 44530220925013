import { Title } from '../UI/Title/Title';
import { Text } from '../UI/Text/Text';
import { WidthLimiter } from '../UI/WidthLimiter';
import cls from './TitleBlock.module.scss';

type Props = {
  title: string;
  text1: string;
  text2: string;
  image: string;
};

export const TitleBlock = ({ title, text1, text2, image }: Props) => {
  return (
    <WidthLimiter>
      <div className={cls.container}>
        <div className={cls.text}>
          <Title>{title}</Title>
          <div className={cls.paragraph}>
            <Text>{text1}</Text>
            <Text>{text2}</Text>
          </div>
        </div>
        <img className={cls.image} src={image} alt="image" />
      </div>
    </WidthLimiter>
  );
};
