import React, { ReactNode } from 'react';
import cls from './Title.module.scss';

interface Props {
  children: ReactNode;
  white?: boolean;
}

export const Title = ({ children, white }: Props) => {
  return (
    <h3
      className={cls.titleBlock}
      style={white ? { color: 'rgba(255, 255, 255, 0.83)' } : {}}
    >
      {children}
    </h3>
  );
};
