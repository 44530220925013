import { WidthLimiterPadding } from '../UI/WidthLimiter';
import { Title } from '../UI/Title/Title';
import { Text } from '../UI/Text/Text';
import { CardListItem } from '../UI/CardListItem/CardListItem';
import cls from './WhyUs.module.scss';
import { useTranslation } from 'react-i18next';

export const WhyUs = () => {
  const { t } = useTranslation();

  return (
    <WidthLimiterPadding>
      <div className={cls.whyUs}>
        <div className={cls.top}>
          <Title>{t('MainPage.WhyUs.Title')}</Title>
          <Text>{t('MainPage.WhyUs.text')}</Text>
        </div>
        <ul className={cls.list}>
          <li>
            <CardListItem count="1" text={t('MainPage.WhyUs.li1')} />
          </li>
          <li>
            <CardListItem count="2" text={t('MainPage.WhyUs.li2')} />
          </li>
          <li>
            <CardListItem count="3" text={t('MainPage.WhyUs.li3')} />
          </li>
          <li>
            <CardListItem count="4" text={t('MainPage.WhyUs.li4')} />
          </li>
          <li>
            <CardListItem count="5" text={t('MainPage.WhyUs.li5')} />
          </li>
          <li>
            <CardListItem count="6" text={t('MainPage.WhyUs.li6')} />
          </li>
          <li>
            <CardListItem count="7" text={t('MainPage.WhyUs.li7')} />
          </li>
          <li>
            <CardListItem count="8" text={t('MainPage.WhyUs.li8')} />
          </li>
        </ul>
      </div>
    </WidthLimiterPadding>
  );
};
